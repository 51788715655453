import moment from 'moment';

/**
  * Builds the current data in DD-MM-YYYY HH:mm:ss format using the moment.js library
  * @returns {string} Date in DD-MM-YYYY HH:mm:ss format
  */
function buildCurrentDate() {
    return moment().format("DD-MM-YYYY HH:mm:ss");
}

/**
 * Logs a message with the current date/time displayed
 * @param {string} message Message to log
 */
export function log(message) {
    console.log(`[${buildCurrentDate()}] ${message}`);
}

/**
  * Obtains the current data from the server that are hosting the application.
  * @returns {Date} Date
  */
export function serverTime() {
    let xmlHttp;

    try {
        //FF, Opera, Safari, Chrome
        xmlHttp = new XMLHttpRequest();
    }
    catch (err1) {
        //IE
        try {
            xmlHttp = new window.ActiveXObject('Msxml2.XMLHTTP');
        }
        catch (err2) {
            try {
                xmlHttp = new window.ActiveXObject('Microsoft.XMLHTTP');
            }
            catch (eerr3) {
                //AJAX not supported.
                log("AJAX not supported. Server time was not obtained.");
            }
        }
    }
    xmlHttp.open('HEAD', window.location.href.toString(), false);
    xmlHttp.setRequestHeader("Content-Type", "text/html");
    xmlHttp.send('');

    return new Date(xmlHttp.getResponseHeader("Date"));
}
