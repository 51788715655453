<template>
  <div id="mapContainer"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import geoJSONCountries from "./assets/custom.geo.json";
import axios from "axios";
import onlineIcon from './assets/online_icon.png';
import offlineIcon from './assets/offline_icon.png';
import ispIcon from './assets/isp_icon.png';
import greenPin from './assets/green_pin.png';
import redPin from './assets/red_pin.png';
import yellowPin from './assets/yellow_pin.png';
import { log } from './utils/utils.js';
import { crono } from 'vue-crono';

// Small
// const HOST_COUNT_ANCHOR = [-6, 28];
// const PIN_ICON_ANCHOR = [4, 24];
// const PIN_ICON_SIZE = [18, 26];
// const PIN_POPUP_ANCHOR = [4, -25];

// Medium
const HOST_COUNT_ANCHOR = [-7, 32];
const PIN_ICON_ANCHOR = [8, 32];
const PIN_ICON_SIZE = [27, 34];
const PIN_POPUP_ANCHOR = [5, -32];

// Big
// const HOST_COUNT_ANCHOR = [-10, 40];
// const PIN_ICON_ANCHOR = [12, 43];
// const PIN_ICON_SIZE = [36, 44];
// const PIN_POPUP_ANCHOR = [4, -36];

let greenPinIcon = L.icon({
    iconUrl: greenPin,
    iconAnchor: PIN_ICON_ANCHOR,
    iconSize: PIN_ICON_SIZE,
    popupAnchor: PIN_POPUP_ANCHOR
});

let yellowPinIcon = L.icon({
    iconUrl: yellowPin,
    iconAnchor: PIN_ICON_ANCHOR,
    iconSize: PIN_ICON_SIZE,
    popupAnchor: PIN_POPUP_ANCHOR
});

let redPinIcon = L.icon({
    iconUrl: redPin,
    iconAnchor: PIN_ICON_ANCHOR,
    iconSize: PIN_ICON_SIZE,
    popupAnchor: PIN_POPUP_ANCHOR
});

export default {
  name: "Map",
  mixins: [crono],

  cron: {
    time: 1000 * 60 * 5, //reload interval in miliseconds
    method: 'loadLayers',
  },

  props: {
    centerPositionLatitude: {
      type: Number,
      default: 60
    },
    centerPositionLongitude: {
      type: Number,
      default: 10
    },
    mapServiceUrl: {
      type: String,
      default: 'https://coral.inesctec.pt/coral-map-service/coral-map'
    },
    centralNodeDomain: {
      type: String,
      default: 'platform.recap-preterm.eu'
    },
    thresholdIsOnlineInHours: {
      type: Number,
      default: 1
    },
    initialZoom: {
      type: Number,
      default: 4
    },
    maxZoom: {
      type: Number,
      default: 5
    },
    minZoom: {
      type: Number,
      default: 3
    },
    zoomControl: {
      type: Boolean,
      default: false
    },
    zoomSnap: {
      type: Number,
      default: 1
    },
    scrollWheelZoom: {
      type: Boolean,
      default: false
    },
    doubleClickZoom: {
      type: Boolean,
      default: false
    },
    dragging: {
      type: Boolean,
      default: true
    },
    tileLayer: {
      type: String,
      default: 'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}'
    },
    tileLayerAttribution: {
      type: String,
      default: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ'
    },
    domainsToIgnore: {
      type: String,
      default: JSON.stringify(['recap.inesctec.pt','recap-test.inesctec.pt'])
    }
  },

  data() {
    return {
      KEY_SEPARATOR: '#',
      map: null,
      markersData: new Map(),
      groupMarkersLayer: null,
      geoJSONLayer: null,
      selectedCountries: new Set(),
    };
  },

  methods: {
    setupLeaflet () {

      log("Configuring Leaflet ...");

      this.map = L.map("mapContainer", { 
                        zoomControl: this.zoomControl, 
                        scrollWheelZoom: this.scrollWheelZoom,
                        zoomSnap: this.zoomSnap, 
                        dragging: this.dragging,
                        doubleClickZoom: this.doubleClickZoom,

                      })
                  .setView([this.centerPositionLatitude, this.centerPositionLongitude], this.initialZoom);
      

      L.tileLayer( this.tileLayer,
        {
          attribution: this.tileLayerAttribution,
          maxZoom: this.maxZoom,
          minZoom: this.minZoom,
        }
      ).addTo(this.map);

      this.geoJSONLayer = L.geoJSON().addTo(this.map);

      this.groupMarkersLayer = L.layerGroup().addTo(this.map);

      log("Leaflet configured.");
    },

    async loadLayers() {
      log("Reloading Layers ...");

      await axios.get(this.mapServiceUrl)
        .then(response => {
            const coralDomains = response.data;

            this.clearMap();
            
            for (let domain of coralDomains) {
              this.handleMarkersData(domain, this.domainsToIgnore);
            }

            this.loadCountriesLayer();

            this.markersData.forEach((value, key) => {
              let latLong = key.split(this.KEY_SEPARATOR);

              L.marker( L.latLng(parseFloat(latLong[0]), parseFloat(latLong[1]) ), {icon: value.icon} )
               .addTo(this.groupMarkersLayer)
               .bindPopup( this.fillMarkerPopup(value) );

              L.marker( L.latLng( parseFloat(latLong[0]), parseFloat(latLong[1]) ), {
                icon: L.divIcon({
                    className: 'host-count',
                    html: `<div class="draw-circle">${value.countTotal}</div>`,
                    iconAnchor: HOST_COUNT_ANCHOR,
                }),
              }).addTo(this.groupMarkersLayer);
            })
        })
        .catch(error => console.error(error));

        log("Layers reloaded ...");
    },

    clearMap() {
      this.markersData = new Map();
      this.selectedCountries = new Set();

      this.groupMarkersLayer.clearLayers();
      this.geoJSONLayer.clearLayers();
    },

    handleMarkersData(domain, domainsToIgnore = null) {

      if ( ( domainsToIgnore !== null && domainsToIgnore.includes( domain.host ) ) 
          || (domain.latitude === null || domain.longitude === null) 
          || (domain.status !== null && domain.status === "fail")
          || ((typeof domain.latitude === 'undefined') || (typeof domain.longitude === 'undefined') ) ) {
        return;
      }

      const key = domain.latitude.toString().concat(this.KEY_SEPARATOR).concat( domain.longitude.toString() );
      const markerDomains = this.markersData.get(key);
      const currentTime = new Date(); 
      const lastActiveTime = new Date(domain.lastActive);

      //Dates with timezone, the comparison will be well executed. 
      //See https://stackoverflow.com/questions/26531212/comparing-dates-in-javascript-and-timezones
      let differenceInHours = (currentTime - lastActiveTime) / 36e5;

      domain.isOnline = ( differenceInHours < this.thresholdIsOnlineInHours);

      if (!markerDomains) {
        let newMarkerDomains = [domain];
        newMarkerDomains.countTotal = newMarkerDomains.length;
        newMarkerDomains.countOnline = (domain.isOnline ? 1 : 0);

        newMarkerDomains.icon = (newMarkerDomains.countOnline === 1 ? greenPinIcon : redPinIcon);

        this.markersData.set(key, newMarkerDomains);
        
      } else {

        markerDomains.push(domain);

        markerDomains.countTotal = markerDomains.length;
        markerDomains.countOnline += (domain.isOnline ? 1 : 0);

        if ((markerDomains.countOnline > 0) && (markerDomains.countOnline === markerDomains.countTotal)) {
          markerDomains.icon = greenPinIcon;
        } else if ((markerDomains.countOnline > 0) && (markerDomains.countOnline < markerDomains.countTotal)) {
          markerDomains.icon = yellowPinIcon;
        } else {
          markerDomains.icon = redPinIcon;
        }

        this.markersData.set(key, markerDomains);
      }

      this.selectedCountries.add(domain.country);
    },

    loadCountriesLayer() {
      this.geoJSONLayer = L.geoJSON(geoJSONCountries, {
         style: this.styleMap,
       }).addTo(this.map);
    },

    styleMap(feature) {
      if ( !this.selectedCountries.has(feature.properties.admin) ) {
        return {color: "#ffffff00"};
      }
    },

    fillMarkerPopup(domains) { 
      let header = `<div class="popup-title"><img src="./assets/images/map/flags/${domains[0].countryCode.toLowerCase()}.png" alt="${domains[0].country}"><b> ${domains[0].city}, ${domains[0].country} </b></div><br>`;
      
      let providerDetails = this.fillProviderDetails(domains[0]);

      let body = (`<div><table id="thosts"><tr>`)
        .concat(`<small><th>Host</th></small>`)
        .concat(`<small><th>DataSHIELD</th></small>`)
        .concat(`<small><th>R Serve</th></small>`)
        .concat(`<small><th>R</th></small>`)
        .concat(`</tr>`)
   
      for (let domain of domains) {
        const lastActiveFormatted = new Date(domain.lastActive);

        body = body.concat(`<tr title="Last active time: ${lastActiveFormatted.toLocaleString()}"><td><b><a href="https://${domain.host}">${domain.host}</a>`)
          .concat(`</b> ${domain.isOnline ? `<img src="${onlineIcon}" title="Online">`: `<img src="${offlineIcon}" title="Offline">`}</td>`)
          .concat(`<td><small>${domain.currentDataSHIELDVersion != null ? domain.currentDataSHIELDVersion : '-'}</small></td>`)
          .concat(`<td><small>${domain.rServeVersion != null ? domain.rServeVersion : '-'}</small></td>`)
          .concat(`<td><small>${domain.rVersion != null ? domain.rVersion : '-'}</small></td>`)
          .concat(`</tr>`)
      }

      body = body.concat(`</table></div>`);

      return header.concat(providerDetails).concat(body);
    },

    fillProviderDetails(domain) {
      return (`<div><table id="tprovider"><tr><th class="isp-icon" rowspan="3"><img src="${ispIcon}" title="Provider details"></th></tr>`)
        .concat(`<tr>`)
        .concat(`<td><small><b>ISP: </b></small></td>`)
        .concat(`<td><small>${domain.isp}</small></td>`)
        .concat(`</tr><tr>`)
        .concat(`<td><small><b>ORG:</b></small></td>`)
        .concat(`<td><small> ${domain.org}</small></td>`)
        .concat(`</tr>`)
        .concat(`</table></div>`)
    },
  },

  mounted() {
    
    if (!document.URL.includes(this.centralNodeDomain)) {
      log("Central Node Domain is different from the current host. The map will not be showed.");
      
      return;
    }

    this.setupLeaflet();
    this.loadLayers();
  },
};
</script>

<style>
#mapContainer {
  width: 30vw;
  height: 100vh;
}

#tprovider {
  float: left;
  border-collapse: collapse;
  margin-bottom: 5px;
}

.isp-icon {
  text-align: left;
}

#tprovider td, #tprovider th {
  padding-right: 1px;
}

#thosts {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

#thosts td, #thosts th {
  border: 1px solid #ddd;
  padding: 6px;
}

#thosts tr:nth-child(even){
  background-color: #f2f2f2;
}

#thosts tr:hover {
  background-color: #ddd;
}

#thosts th {
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: left;
  background-color: #234c5e;
  color: white;
}

.popup-title {
  color: #234c5e;
}

.draw-circle {
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  font-size: 11px;  
  color: white;
  border-radius: 50%;
  background-color: darkblue;
  border: 1px black;
  display: inline-block;
}

.leaflet-popup-content {
  width: auto!important;
}

</style>
